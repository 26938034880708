@keyframes skyloaderBaloon {
  0% {
    margin-top: -286px;
  }
  50% {
    margin-top: -326px;
  }
  100% {
    margin-top: -286px;
  }
}